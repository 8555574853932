export default function getBusinessSchemaMarkup() {
    return {
        '@context': 'https://schema.org',
        '@type': 'LocalBusiness',
        name: 'Yummy Bros',
        image:
            'https://yb-production.s3.ap-southeast-1.amazonaws.com/images/static/logo-small.png',
        '@id': 'https://www.yummybros.com/',
        url: 'https://www.yummybros.com/',
        address: {
            '@type': 'PostalAddress',
            streetAddress: '5 Mandai Link, Singapore',
            addressLocality: 'Singapore',
            postalCode: '728654',
            addressCountry: 'SG',
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: 1.4098055,
            longitude: 103.7580831,
        },
        openingHoursSpecification: [
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: 'Monday',
                opens: '09:00',
                closes: '22:00',
            },
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: 'Tuesday',
                opens: '10:00',
                closes: '18:00',
            },
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: 'Wednesday',
                opens: '10:00',
                closes: '22:00',
            },
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: 'Thursday',
                opens: '09:00',
                closes: '22:00',
            },
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: 'Friday',
                opens: '10:00',
                closes: '18:00',
            },
            {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: 'Sunday',
                opens: '11:00',
                closes: '22:00',
            },
        ],
        sameAs: [
            'https://www.facebook.com/yummybros/',
            'https://www.instagram.com/yummybros/',
            'https://www.youtube.com/channel/UCApi_DFsoYOdKgiuJIHcVcg',
        ],
    };
}
