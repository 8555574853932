import { useListCRUD } from 'lib/swr-crud';
import { getProducts } from './api';

const fetchers = {
  read: async (_url, query) => {
    const { response, error } = await getProducts({ query });

    if (error) throw error;

    return response.data;
  },
};

const useProducts = (options = {}) => {
  let url = '/products';

  const {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useListCRUD(url, 'id', {
    ...options,
    fetchers,
  });

  const products = data || [];

  return {
    products,
    error,
    isLoading,
    isValidating,
    mutate,
  };
};

export default useProducts;
