/* eslint-disable react/no-danger */
import Head from 'next/head';
import PropTypes from 'prop-types';
import getBusinessSchemaMarkup from './BusinessSchemaMarkup';

const HeaderSEO = ({
  title,
  description,
  path,
  image,
  faqData,
  ogType,
  includeBusinessMarkup,
  preventIndex,
}) => {
  // let pagePath = path;
  // if (
  //   path !== undefined &&
  //   path !== null &&
  //   path.substring(1, 0) !== '/'
  // ) {
  //   pagePath = `/${path}`;
  // }
  // const pageURL = `${process.env.NEXT_PUBLIC_DOMAIN_URL}${pagePath}`;

  const pageURL = `${path}`;

  let robotsContent = 'follow, index';
  if (preventIndex) {
    robotsContent = 'nofollow, noindex';
  } else if (
    process.env.NEXT_PUBLIC_ROBOT_INDEX === undefined ||
    process.env.NEXT_PUBLIC_ROBOT_INDEX === null ||
    process.env.NEXT_PUBLIC_ROBOT_INDEX.toUpperCase() === 'N'
  ) {
    robotsContent = 'nofollow, noindex';
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Yummy Bros" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, yesapple-mobile-web-app-capable yes"
        />
        <meta
          property="og:article_publisher"
          content="http://www.facebook.com/yummybros"
        />
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        {path !== undefined && path !== null && path.length > 0 && (
          <>
            <link rel="canonical" href={pageURL} />
            <meta property="og:url" content={pageURL} />
          </>
        )}
        {description !== undefined &&
          description !== null &&
          description.length > 0 && (
            <>
              <meta name="description" content={description} />
              <meta property="og:description" content={description} />
              <meta
                name="twitter:description"
                content={description}
              />
            </>
          )}
        {image !== undefined && image !== null && image.length > 0 && (
          <>
            <meta property="og:image" content={image} />
            <meta name="twitter:image" content={image} />
          </>
        )}
        {includeBusinessMarkup && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(getBusinessSchemaMarkup()),
            }}
            async
          />
        )}
        {faqData !== undefined &&
          faqData !== null &&
          Array.isArray(faqData) && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                  '@context': 'https://schema.org',
                  '@type': 'FAQPage',
                  mainEntity: faqData.map((faq) => ({
                    '@type': 'Question',
                    name: faq.question,
                    acceptedAnswer: {
                      '@type': 'Answer',
                      text: faq.answer,
                    },
                  })),
                }),
              }}
            />
          )}
        <meta
          property="og:type"
          content={ogType ? ogType : 'website'}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="robots" content={robotsContent} />
      </Head>
    </>
  );
};

HeaderSEO.defaultProps = {
  description: null,
  path: null,
  image:
    'https://yb-production.s3.ap-southeast-1.amazonaws.com/images/static/facebook-share-image-2020.jpeg',
  faqData: null,
  includeBusinessMarkup: false,
  preventIndex: false,
};

HeaderSEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  path: PropTypes.string,
  image: PropTypes.string,
  faqData: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    }),
  ),
  includeBusinessMarkup: PropTypes.bool,
  preventIndex: PropTypes.bool,
};

export default HeaderSEO;
