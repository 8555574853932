import { useState } from 'react';

import notification from 'lib/notification';
import * as analytics from 'lib/analytics';

import { useCart } from './CartContext';

const useAddToCart = ({
  product,
  productVariant,
  quantity,
  addonOptionValueIds,
}) => {
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const { loading: isCartLoading, addToCart: _addToCart } = useCart();

  const isOutOfStock = productVariant?.stock === 0;

  const addToCart = async () => {
    try {
      setIsAddingToCart(true);

      if (isOutOfStock) throw new Error('Out of stock');

      if (quantity > productVariant.stock)
        throw new Error('Not enough stock');

      const _quantity = quantity || 1;

      const updatedCart = await _addToCart({
        product,
        productVariantId: productVariant.id,
        quantity: _quantity,
        addonOptionValueIds: addonOptionValueIds || [],
      });

      analytics.addToCart({
        product,
        productVariant,
        quantity: _quantity,
        updatedCart,
      });

      notification.success('YAY! Successfully added to cart!');
    } catch (err) {
      notification.error(err.message);
    } finally {
      setIsAddingToCart(false);
    }
  };

  const loading = isCartLoading;

  const canAddToCart = !(loading || isOutOfStock);

  return {
    loading,
    canAddToCart,
    addToCart,
    isOutOfStock,
    isAddingToCart,
  };
};

export default useAddToCart;
