import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/common';

const SelectOptionsButton = ({ disabled, onClick }) => {
  return (
    <button
      className="btn-product"
      disabled={disabled}
      onClick={onClick}
    >
      <Icon iconSet="bootstrap" name="list-check" fontSize={18} />
      <span>Customize Meal</span>
    </button>
  );
};

SelectOptionsButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

SelectOptionsButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export default SelectOptionsButton;
