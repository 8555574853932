import httpClient, {
  getResponseData,
  getErrorMessage,
} from 'lib/httpClient';

export const getProducts = async ({ query } = {}) => {
  try {
    const response = await httpClient.get('/products', {
      params: query,
    });

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const getProductById = async (productId) => {
  try {
    const response = await httpClient.get(`/products/${productId}`);

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export const getProductBySlug = async (slug) => {
  try {
    const response = await httpClient.get(`/products/${slug}/slug`);

    return { response: getResponseData(response) };
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};
