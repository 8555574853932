import PropTypes from 'prop-types';

import { typedef as productTypedef } from 'lib/product';
import { typedef } from 'lib/productVariants';

import useAddToCart from './useAddToCart';

/**
 * @typedef {Object} AddToCartRenderProps
 * @property {Boolean} loading
 * @property {Function} addToCart
 * @property {Boolean} isAddingToCart
 * @property {Boolean} isOutOfStock
 * @property {Boolean} canAddToCart
 */

/**
 * @callback children
 * @param {AddToCartRenderProps} renderProps
 */

/**
 *
 * @param {Object} props
 * @param {children} props.children
 *
 */
const AddToCart = ({
  product,
  productVariant,
  quantity,
  addonOptionValueIds,
  children,
}) => {
  const renderProps = useAddToCart({
    product,
    productVariant,
    quantity,
    addonOptionValueIds,
  });

  return children(renderProps);
};

AddToCart.propTypes = {
  product: productTypedef.Product.isRequired,
  productVariant: typedef.ProductVariant.isRequired,
  children: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  addonOptionValueIds: PropTypes.arrayOf(PropTypes.string),
};

export default AddToCart;
