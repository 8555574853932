import React from 'react';
import PropTypes from 'prop-types';

const QuickAddToCartButton = ({
  disabled,
  onClick,
  isCartLoading,
  isAddingToCart,
}) => {
  return (
    <button
      className="btn-product btn-cart"
      disabled={disabled}
      onClick={onClick}
    >
      <span>
        {isCartLoading
          ? 'Loading...'
          : isAddingToCart
          ? 'Adding to Cart...'
          : 'Add to Cart'}
      </span>
    </button>
  );
};

QuickAddToCartButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isCartLoading: PropTypes.bool,
  isAddingToCart: PropTypes.bool,
};

QuickAddToCartButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  isCartLoading: false,
  isAddingToCart: false,
};

export default QuickAddToCartButton;
