import PropTypes from 'prop-types';

export const Variant = PropTypes.shape({
  stock: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
});

export const Asset = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  extension: PropTypes.string,
  url: PropTypes.string.isRequired,
  size: PropTypes.number,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
});

export const Product = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  short_description: PropTypes.string,
  description: PropTypes.string,
  variants: PropTypes.arrayOf(Variant).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  assets: PropTypes.arrayOf(Asset),
  published_at: PropTypes.string.isRequired,
});

export const ProductFilters = PropTypes.shape({
  value: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  sortBy: PropTypes.string.isRequired,
  category: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.arrayOf(PropTypes.string).isRequired,
  brand: PropTypes.arrayOf(PropTypes.string).isRequired,
  rating: PropTypes.arrayOf(PropTypes.number).isRequired,
});
