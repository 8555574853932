import PropTypes from 'prop-types';

export const ProductVariant = PropTypes.shape({
  sku: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  stock: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
});

export const ProductOptionValue = PropTypes.shape({
  value: PropTypes.string.isRequired,
  position: PropTypes.number,
});

export const ProductOption = PropTypes.shape({
  label: PropTypes.string.isRequired,
  position: PropTypes.number,
  options: PropTypes.arrayOf(ProductOptionValue),
});
